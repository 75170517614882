import React from 'react';
import { Col, Row, Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import StatusStaffTabsRow from 'Components/StatusStaffTabsRow';
import FlightCard from 'Views/TicketProfile/Components/FlightCard';
import { DS_F2W_Business_Travel_Team } from 'Constants/F2wRolesConstants';

const BusinessTravelTeamTab = (props) => {
  const { flyToWorkTicketInfo, setRefreshComponent } = props;
  // console.log('flyToWorkTicketInfo', flyToWorkTicketInfo);

  const user = useSelector((state) => state.currentUser);
  const userRoles = user?.roles;

  const [showAddFlightModal, setShowAddFlightModal] = React.useState(false);

  const isInsertFlightTicketDisabled = () => {
    let result = true;

    if (userRoles.includes(DS_F2W_Business_Travel_Team) && flyToWorkTicketInfo.status?.toLowerCase() === 'pendingbtt')
      result = false;

    return result;
  };

  return (
    <React.Fragment>
      <StatusStaffTabsRow flyToWorkTicketInfo={flyToWorkTicketInfo} />
      <hr style={{ marginTop: 0 }} />
      <Row>
        <Col>
          <Button
            style={{ width: '100%', marginBottom: '0.5%' }}
            variant="info"
            size="sm"
            onClick={() => {
              setShowAddFlightModal(true);
            }}
            disabled={isInsertFlightTicketDisabled()}
          >
            Add flight to this ticket
          </Button>
          {flyToWorkTicketInfo.flights &&
            flyToWorkTicketInfo.flights.map((flight, key) => {
              // console.log('flight', flight);
              if (!flight.bookingReference) return null;
              flight.isSent = true;
              return (
                <FlightCard
                  key={key}
                  flight={flight}
                  flyToWorkTicketInfo={flyToWorkTicketInfo}
                  setRefreshComponent={setRefreshComponent}
                  setShowAddFlightModal={setShowAddFlightModal}
                />
              );
            })}
        </Col>
      </Row>

      <Modal size="lg" show={showAddFlightModal} onHide={() => setShowAddFlightModal(false)}>
        <Modal.Header closeButton>New flight</Modal.Header>
        <FlightCard
          flyToWorkTicketInfo={flyToWorkTicketInfo}
          flight={{
            confirmedFlightDate: '',
            bookingReference: '',
            flightNumber: '',
            departureAirport: '',
            flightDepartureTime: '',
            arrivalAirport: '',
            flightArrivalTime: '',
            travelType: '',
            paymentMethod: '',
            luggage: '',
            xbagCost: '',
            flightCost: '',
            hotelCost: '',
            costCentre: '',
          }}
          setRefreshComponent={setRefreshComponent}
          setShowAddFlightModal={setShowAddFlightModal}
          showCloseButtonOnModal={true}
        />
      </Modal>
    </React.Fragment>
  );
};

export default BusinessTravelTeamTab;
