import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import StatusAllAgGridTable from 'Views/All/AgGridTable/StatusAllAgGridTable';
import RestClient from 'Services/RestClientService';
import { DS_F2W_Business_Travel_Team, DS_F2W_HR_Team } from 'Constants/F2wRolesConstants';
import { setLoader } from 'Redux/Actions/Loading/LoadingAction';
import { toastr } from 'react-redux-toastr';

const StatusOverview = () => {
  const dispatch = useDispatch();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const user = useSelector((state) => state.currentUser);
  const userRoles = user?.roles;

  const createExcelReport = () => {
    dispatch(setLoader(true));
    RestClient.Post('excelNew', {})
      .then((data) => {
        if (data.message === 'Processing') {
          toastr.warning(
            'Processing',
            "Your report is now processing. You will soon receive it via email. The export button is now disabled for 2' to generate the file."
          );
        }
        dispatch(setLoader(false));
        setIsButtonDisabled(true);
        const disabledUntil = new Date(new Date().getTime() + 120000);
        localStorage.setItem('disabledUntil', disabledUntil);
        setTimeout(() => {
          setIsButtonDisabled(false);
          localStorage.removeItem('disabledUntil');
        }, 120000);
      })
      .catch(() => {
        dispatch(setLoader(false));
      });
  };

  useEffect(() => {
    document.title = 'F2W - Overview';
    const disabledUntil = localStorage.getItem('disabledUntil');
    if (disabledUntil) {
      const timeLeft = new Date(disabledUntil).getTime() - new Date().getTime();
      if (timeLeft > 0) {
        setIsButtonDisabled(true);
        setTimeout(() => {
          setIsButtonDisabled(false);
          localStorage.removeItem('disabledUntil');
        }, timeLeft);
      }
    }
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <h1>Overview</h1>

          <Button
            variant="primary"
            size="sm"
            style={{ margin: '0 2%' }}
            onClick={() => {
              createExcelReport();
            }}
            disabled={isButtonDisabled}
            hidden={!userRoles.includes(DS_F2W_Business_Travel_Team) && !userRoles.includes(DS_F2W_HR_Team)}
          >
            Export excel report
          </Button>
        </Col>
      </Row>
      <StatusAllAgGridTable />
    </React.Fragment>
  );
};

export default StatusOverview;
