import React, { useEffect } from 'react';

import StatusPendingHrAgGridTable from 'Views/PendingHr/AgGridTable/StatusPendingHrAgGridTable';

const StatusPendingHr = () => {
  useEffect(() => {
    document.title = 'F2W - PendingHr';
  }, []);

  return (
    <React.Fragment>
      <h1>Pending HR</h1>
      <StatusPendingHrAgGridTable />
    </React.Fragment>
  );
};

export default StatusPendingHr;
