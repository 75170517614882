import {
  GET_SOURCEMARKETS_SUCCESS,
  GET_JOBFAMILIES_SUCCESS,
  GET_POSITION_TYPES_SUCCESS,
  GET_SEASONS_SUCCESS,
  GET_DESTINATIONS_SUCCESS,
  GET_JOBTITLES_SUCCESS,
  GET_COUNTRIES_SUCCESS,
  GET_REGIONS_SUCCESS,
  GET_KEYWORDS_SUCCESS,
  GET_MPLIDS_SUCCESS,
  GET_STAFF_STATUS_SUCCESS,
  GET_STAFF_NATIONALITIES_SUCCESS,
  GET_LANGUAGES_SUCCESS,
  GET_LANGUAGE_LEVEL_SUCCESS,
} from '../../../Constants/MasterDataConstants';

// Initial state
const initialState = {
  sourceMarkets: [],
  destinations: [],
  seasons: [],
  jobTitles: [],
  jobFamilies: [],
  positionTypes: [],
  countries: [],
  regions: [],
  keywords: [],
  mplids: [],
  staffStatus: [],
  staffNationalities: [],
  languages: [],
  languageLevels: [],
};

// Reducer function
const MasterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SOURCEMARKETS_SUCCESS:
      return {
        ...state,
        sourceMarkets: action.data.sourceMarkets,
      };
    case GET_JOBFAMILIES_SUCCESS:
      return {
        ...state,
        jobFamilies: action.data.jobFamilies,
      };
    case GET_POSITION_TYPES_SUCCESS:
      return {
        ...state,
        positionTypes: action.data.positionTypes,
      };
    case GET_DESTINATIONS_SUCCESS:
      return {
        ...state,
        destinations: action.data.destinations,
      };
    case GET_JOBTITLES_SUCCESS:
      return {
        ...state,
        jobTitles: action.data.jobTitles,
      };
    case GET_SEASONS_SUCCESS:
      return {
        ...state,
        seasons: action.data.seasons,
      };
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.data.countries,
      };
    case GET_REGIONS_SUCCESS:
      return {
        ...state,
        regions: action.data.regions,
      };
    case GET_KEYWORDS_SUCCESS:
      return {
        ...state,
        keywords: action.data.keywords,
      };
    case GET_MPLIDS_SUCCESS:
      return {
        ...state,
        mplids: action.data.mplids,
      };

    case GET_STAFF_STATUS_SUCCESS:
      return {
        ...state,
        staffStatus: action.data.staffStatus,
      };

    case GET_STAFF_NATIONALITIES_SUCCESS:
      return {
        ...state,
        staffNationalities: action.data.staffNationalities,
      };

    case GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: action.data.languages,
      };

    case GET_LANGUAGE_LEVEL_SUCCESS:
      return {
        ...state,
        languageLevels: action.data.languageLevels,
      };
    default:
      return state;
  }
};

export default MasterDataReducer;
