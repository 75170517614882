import React, { useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import RestClient from 'Services/RestClientService';
import { setLoader } from 'Redux/Actions/Loading/LoadingAction';
import { DATE_FORMAT_WEB } from 'Constants/DateFormatConstants';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';
import { handleDeleteFlightTicket } from 'Handlers/GlobalFunctions';
import { DS_F2W_Business_Travel_Team } from 'Constants/F2wRolesConstants';

const StatusConfirmedAgGridTable = () => {
  const gridRef = useRef(null);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.currentUser);
  const userRoles = user?.roles;

  useEffect(() => {
    // remove last element of the columnDefs array if the user is not DS_F2W_HR_Team
    if (userRoles.length > 0 && !userRoles.includes(DS_F2W_Business_Travel_Team)) {
      console.log('columnDefs', columnDefs.length);
      columnDefs.pop();
    }
    gridRef.current.api.setColumnDefs(columnDefs);
  }, [userRoles]);

  const [columnDefs] = useState([
    {
      headerName: 'Status',
      field: 'status',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
    },
    {
      headerName: 'First Name',
      field: 'firstName',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
    },
    {
      headerName: 'Destination',
      field: 'destination',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
    },
    {
      headerName: 'Country',
      field: 'country',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
    },
    {
      headerName: 'Season',
      field: 'season',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
    },
    {
      headerName: 'Source Market (staff)',
      field: 'staffSourceMarket',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
    },
    {
      headerName: 'Conflirmed flight date',
      field: 'confirmedFlightDate',
      filter: 'agDateColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
      valueFormatter: (params) => {
        if (params.data) {
          return DateTimeFormater(
            params.data.flights[params.data.flights.length - 1]?.confirmedFlightDate,
            null,
            DATE_FORMAT_WEB
          );
        }
      },
    },
    {
      headerName: 'Planned assigment date',
      field: 'plannedAssignmentStartDate',
      filter: 'agDateColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
      valueFormatter: (params) => {
        if (params.value) {
          return DateTimeFormater(params.value, null, DATE_FORMAT_WEB);
        }
        return '';
      },
    },
    {
      headerName: 'Type of flight',
      field: 'typeOfFlight',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      width: 150,
    },
    {
      headerName: 'Actions',
      cellRenderer: (params) => {
        const button = document.createElement('button');
        button.classList.add('btn', 'btn-danger', 'btn-sm');
        button.style = 'margin: 0 5px;padding:0.5% 1%;border-radius: 5px;cursor: pointer; font-size: 0.8rem;';
        button.textContent = 'Delete';
        button.addEventListener('click', () =>
          handleDeleteFlightTicket(params.data.id, gridRef, createServerSideDatasource)
        );
        return button;
      },
    },
  ]);

  const createServerSideDatasource = () => {
    dispatch(setLoader(true));
    return {
      getRows: (params) => {
        // change this in the tpp-api because is the type of the query
        // this is in staff controller
        RestClient.Post('staff/agGridTable/All', params.request)
          .then((response) => {
            if (response) {
              // console.log('[response] - rows : ', response);
              if (response.totalRows === 0) {
                gridRef.current.api.showNoRowsOverlay();
              }
              params.successCallback(response.rows, response.rowCount);
            } else {
              params.failCallback();
            }
          })
          .finally(() => {
            dispatch(setLoader(false));
          });
      },
    };
  };

  const onGridReady = (params) => {
    params.api.setServerSideDatasource(createServerSideDatasource());
    params.api.sizeColumnsToFit();
  };

  const onRowDoubleClicked = (event) => {
    // console.log('event', event.data);
    var url = location.protocol + '//' + location.host + '/' + event.data.status + '/' + event.data.id;
    window.open(url, '_blank');
  };

  const getRowStyle = (params) => {
    if (params.data?.confirmedStatus?.toLowerCase() === 'modified') {
      return { background: '#fcdab6' };
    }
    if (params.data?.confirmedStatus?.toLowerCase() === 'cancelled') {
      return { background: '#fcaeae' };
    }
    if (params.data?.status?.toLowerCase() === 'confirmed') {
      return { background: '#c9f2d6' };
    }

    return { background: '' };
  };

  return (
    <React.Fragment>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="ag-theme-fresh" style={{ height: '700px', width: '100%', padding: '1% 0' }}>
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            rowModelType="serverSide"
            onGridReady={onGridReady}
            pagination={true}
            paginationPageSize={100}
            cacheBlockSize={100}
            maxBlocksInCache={1}
            floatingFilter={true}
            animateRows={true}
            columnHoverHighlight={true}
            debug={false}
            onRowDoubleClicked={onRowDoubleClicked}
            getRowStyle={getRowStyle}
          ></AgGridReact>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StatusConfirmedAgGridTable;
