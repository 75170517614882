import RestClient from 'Services/RestClientService';
import React, { useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';

const F2wSideBar = () => {
  const [ticketCounterList, setTicketCounterList] = React.useState({
    data: {
      totalAllStatus: 0,
      totalNew: 0,
      totalPendinghr: 0,
      totalPendingbtt: 0,
      totalPendingdes: 0,
      totalConfirmed: 0,
    },
  });

  useEffect(() => {
    RestClient.Get('flight-ticket/count').then((response) => {
      // console.log(response);
      setTicketCounterList(response);
    });
  }, [window.location.pathname]);

  const activeStyle = {
    backgroundColor: 'white',
    color: '#007bff',
    borderLeft: '3px solid #007bff',
    a: {
      textDecoration: 'none',
      color: 'blue',
    },
  };

  const inactiveStyle = {
    backgroundColor: '#f8f9fa',
    borderLeft: '3px solid grey',
    a: {
      textDecoration: 'none',
      color: 'grey',
    },
  };

  const checkActiveSideBarLink = (activeLink) => {
    // TODO : tricky part where when redirects from / to /new, it doesn't work
    // i have to foce the activeLink to be new in order to active link in the sidebar
    if (window.location.pathname === '/') activeLink = 'new';

    if (window.location.pathname.includes(activeLink)) return activeStyle;
    return inactiveStyle;
  };

  const ALL_STATUS = 'all';
  const NEW_STATUS = 'new';
  const PENDING_HR = 'pendinghr';
  const PENDING_BTT = 'pendingbtt';
  const PENDING_DES = 'pendingdes';
  const CONFIRMED = 'confirmed';

  return (
    <React.Fragment>
      <ListGroup variant="flush">
        <ListGroup.Item style={checkActiveSideBarLink(NEW_STATUS)}>
          <i className="fa fa-user-plus mr-2"></i>
          <a style={checkActiveSideBarLink(NEW_STATUS).a} href="/new">
            New <small>({ticketCounterList?.data.totalNew})</small>
          </a>
        </ListGroup.Item>
        <ListGroup.Item style={checkActiveSideBarLink(PENDING_HR)}>
          <i className="fa fa-handshake-o mr-2" />
          <a style={checkActiveSideBarLink(PENDING_HR).a} href="/pendinghr">
            Pending HR <small>({ticketCounterList?.data.totalPendinghr})</small>
          </a>
        </ListGroup.Item>
        <ListGroup.Item style={checkActiveSideBarLink(PENDING_BTT)}>
          <i className="fa fa-exchange mr-2" />
          <a style={checkActiveSideBarLink(PENDING_BTT).a} href="/pendingbtt">
            Pending BTT <small>({ticketCounterList?.data.totalPendingbtt})</small>
          </a>
        </ListGroup.Item>
        <ListGroup.Item style={checkActiveSideBarLink(PENDING_DES)}>
          <i className="fa fa-fort-awesome mr-2" />
          <a style={checkActiveSideBarLink(PENDING_DES).a} href="/pendingdes">
            Pending DES <small>({ticketCounterList?.data.totalPendingdes})</small>
          </a>
        </ListGroup.Item>
        <ListGroup.Item style={checkActiveSideBarLink(CONFIRMED)}>
          <i className="fa fa-plane mr-2" />
          <a style={checkActiveSideBarLink(CONFIRMED).a} href="/confirmed">
            Confirmed <small>({ticketCounterList?.data.totalConfirmed})</small>
          </a>
        </ListGroup.Item>
        <ListGroup.Item style={checkActiveSideBarLink(ALL_STATUS)}>
          <i className="fa fa-users mr-2"></i>
          <a style={checkActiveSideBarLink(ALL_STATUS).a} href="/all">
            Overview <small>({ticketCounterList.data.totalAllStatus})</small>
          </a>
        </ListGroup.Item>
      </ListGroup>
    </React.Fragment>
  );
};

export default F2wSideBar;
