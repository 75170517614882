import React, { useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useDispatch } from 'react-redux';

import { DATE_FORMAT_WEB } from 'Constants/DateFormatConstants';
import { setLoader } from 'Redux/Actions/Loading/LoadingAction';
import RestClient from 'Services/RestClientService';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';

const NewAgGridTable = () => {
  const gridRef = useRef(null);
  const dispatch = useDispatch();

  const [columnDefs] = useState([
    {
      headerName: 'First Name',
      field: 'firstName',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
    },
    {
      headerName: 'Destination',
      field: 'destination',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
    },
    {
      field: 'country',
      headerName: 'Country',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
    },
    {
      headerName: 'Season',
      field: 'season',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
    },
    {
      headerName: 'Source Market (staff)',
      field: 'staffSourceMarket',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
    },
    {
      headerName: 'Planned assigment date',
      field: 'plannedAssignmentStartDate',
      filter: 'agDateColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
      valueFormatter: (params) => {
        if (params.value) {
          return DateTimeFormater(params.value, null, DATE_FORMAT_WEB);
        }
      },
    },
    {
      headerName: 'Type of flight',
      field: 'typeOfFlight',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      width: 150,
    },
    // {
    //   headerName: 'Actions',
    //   cellRenderer: (params) => {
    //     const button = document.createElement('button');
    //     button.classList.add('btn', 'btn-primary', 'btn-sm');
    //     button.style = 'margin: 0 5px;padding:0.5% 1%;border-radius: 5px;cursor: pointer; font-size: 0.8rem;';
    //     button.textContent = 'Download PDF';
    //     button.addEventListener('click', () =>
    //       handleDownloadPdf(params.data.id, params.data.firstName + ' ' + params.data.lastName + '.pdf')
    //     );
    //     return button;
    //   },
    // },
  ]);

  const createServerSideDatasource = () => {
    dispatch(setLoader(true));
    return {
      getRows: (params) => {
        RestClient.Post('staff/agGridTable/New', params.request)
          .then((response) => {
            if (response) {
              // console.log('[response] - rows : ', response);
              if (response.totalRows === 0) {
                gridRef.current.api.showNoRowsOverlay();
              }
              params.successCallback(response.rows, response.rowCount);
            } else {
              params.failCallback();
            }
          })
          .finally(() => {
            dispatch(setLoader(false));
          });
      },
    };
  };

  const onGridReady = (params) => {
    params.api.setServerSideDatasource(createServerSideDatasource());
    params.api.sizeColumnsToFit();
  };

  const onRowDoubleClicked = (event) => {
    var url = location.protocol + '//' + location.host + '/new/' + event.data.id;
    window.open(url, '_blank');
  };

  return (
    <React.Fragment>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="ag-theme-fresh" style={{ height: '700px', width: '100%', padding: '1% 0' }}>
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            rowModelType="serverSide"
            onGridReady={onGridReady}
            pagination={true}
            paginationPageSize={100}
            cacheBlockSize={100}
            maxBlocksInCache={1}
            floatingFilter={true}
            animateRows={true}
            columnHoverHighlight={true}
            debug={false}
            onRowDoubleClicked={onRowDoubleClicked}
          ></AgGridReact>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewAgGridTable;
