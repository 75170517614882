import React from 'react';
import { Card, Form, Col, Row } from 'react-bootstrap';
import Datetime from 'react-datetime';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { toastr } from 'react-redux-toastr';
// import moment from 'moment';
import { useSelector } from 'react-redux';

import { DATETIME_FORMAT_ISO, DATE_FORMAT_ISO, DATE_FORMAT_WEB } from 'Constants/DateFormatConstants';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';
// import file
import airports from 'Constants/airports.json';
import RestClient from 'Services/RestClientService';
import { DS_F2W_Edit, DS_F2W_HR_Team, DS_F2W_Business_Travel_Team } from 'Constants/F2wRolesConstants';

const BusinessSupportCard = (props) => {
  const { flyToWorkTicketInfo, setRefreshComponent } = props;
  //console.log('flyToWorkTicketInfo', flyToWorkTicketInfo);
  const mappedAirports = airports.map((airport) => {
    return {
      value: airport.iata_code,
      label: `${airport.iata_code} - ${airport.name}`,
    };
  });

  const user = useSelector((state) => state.currentUser);
  const userRoles = user?.roles;

  const handleUpdateFlightTicketInfo = (fieldName, fieldValue) => {
    var payload = {
      [fieldName]: fieldValue,
    };

    // console.log('payload', payload);
    RestClient.Post(`flight-ticket/update/${flyToWorkTicketInfo.id}`, payload).then((response) => {
      console.log('response', response);
      setRefreshComponent(Math.random());
    });
  };

  // based on the visibility rules

  const isPreferredFlightDateDisabled = () => {
    // when is editable for HR team
    if (
      userRoles?.includes(DS_F2W_HR_Team) &&
      flyToWorkTicketInfo.noFlightNeeded &&
      flyToWorkTicketInfo.status?.toLowerCase() === 'pendinghr'
    )
      return false;

    // when is editable for OS team
    if (
      userRoles?.includes(DS_F2W_Edit) &&
      (flyToWorkTicketInfo.status?.toLowerCase() === 'pendingdes' ||
        flyToWorkTicketInfo.status?.toLowerCase() === 'new')
    )
      return false;

    return true;
  };
  const isArrivalDepartureAirportDisabled = () => {
    // if this flag is setted , never should be editable the airports
    if (flyToWorkTicketInfo.noFlightNeeded) return true;

    // when is editable for OS team
    if (
      userRoles?.includes(DS_F2W_Edit) &&
      (flyToWorkTicketInfo.status?.toLowerCase() === 'pendingdes' ||
        flyToWorkTicketInfo.status?.toLowerCase() === 'confirmed' ||
        flyToWorkTicketInfo.status?.toLowerCase() === 'new')
    )
      return false;

    return true;
  };

  const isOSFieldDisabled = () => {
    let result = true;

    if (
      userRoles?.includes(DS_F2W_Business_Travel_Team) &&
      (flyToWorkTicketInfo.status?.toLowerCase() === 'pendingdes' ||
        flyToWorkTicketInfo.status?.toLowerCase() === 'confirmed' ||
        flyToWorkTicketInfo.status?.toLowerCase() === 'pendingbtt')
    )
      result = false;

    if (
      userRoles?.includes(DS_F2W_Edit) &&
      (flyToWorkTicketInfo.status?.toLowerCase() === 'pendingdes' ||
        flyToWorkTicketInfo.status?.toLowerCase() === 'new')
    )
      result = false;

    return result;
  };

  const isOSFieldDisabledNoTravelNeeded = () => {
    let result = true;

    if (
      userRoles?.includes(DS_F2W_Edit) &&
      (flyToWorkTicketInfo.status?.toLowerCase() === 'pendingdes' ||
        flyToWorkTicketInfo.status?.toLowerCase() === 'new')
    )
      result = false;

    return result;
  };

  const isGreenLightCheckBoxDisabled = () => {
    let result = true;

    if (userRoles?.includes(DS_F2W_HR_Team) && flyToWorkTicketInfo?.status?.toLowerCase() === 'pendinghr')
      result = false;

    return result;
  };

  return (
    <Card className="staff-profile-card">
      <Card.Header>
        <i className="fa fa-check mr-2"></i> Business support information
      </Card.Header>
      <Card.Body>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>First Name</Form.Label>
            <Form.Control type="text" defaultValue={flyToWorkTicketInfo.firstName} readOnly />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Last Name</Form.Label>
            <Form.Control type="text" defaultValue={flyToWorkTicketInfo.lastName} readOnly />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Second Last Name</Form.Label>
            <Form.Control type="text" defaultValue={flyToWorkTicketInfo.lastName2} readOnly />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Date of birth</Form.Label>
            <Datetime
              timeFormat={false}
              dateFormat={DATE_FORMAT_WEB}
              closeOnSelect
              utc={true}
              inputProps={{ placeholder: DATE_FORMAT_WEB, disabled: true }}
              value={DateTimeFormater(flyToWorkTicketInfo.dateOfBirth, null, DATE_FORMAT_WEB)}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Phone</Form.Label>
            <Form.Control type="text" defaultValue={flyToWorkTicketInfo.phone} readOnly />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Gender</Form.Label>
            <Row>
              <Col>
                <Form.Check
                  inline
                  label="Male"
                  name="gender_radio_button"
                  type="radio"
                  id="male_radio_button"
                  value="Male"
                  checked={flyToWorkTicketInfo.gender?.toLowerCase() === 'male'}
                  disabled
                />
                <Form.Check
                  inline
                  label="Female"
                  name="gender_radio_button"
                  type="radio"
                  id="female_radio_button"
                  value="Female"
                  checked={flyToWorkTicketInfo.gender?.toLowerCase() === 'female'}
                  disabled
                />
                <Form.Check
                  inline
                  label="Unknow"
                  name="gender_radio_button"
                  type="radio"
                  id="unknow_radio_button"
                  value="Unknow"
                  checked={!flyToWorkTicketInfo.gender}
                  disabled
                />
              </Col>
            </Row>
          </Form.Group>
        </Form.Row>
        <hr></hr>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Staff Source Market</Form.Label>
            <Form.Control type="text" defaultValue={flyToWorkTicketInfo.staffSourceMarket} readOnly />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Source Market Budget</Form.Label>
            <Form.Control type="text" defaultValue={flyToWorkTicketInfo.staffSourceMarketBudget} readOnly />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Season</Form.Label>
            <Form.Control type="text" defaultValue={flyToWorkTicketInfo.season} readOnly />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Country</Form.Label>
            <Form.Control type="text" defaultValue={flyToWorkTicketInfo.country} readOnly />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Destination</Form.Label>
            <Form.Control type="text" defaultValue={flyToWorkTicketInfo.destination} readOnly />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Position Job title</Form.Label>
            <Form.Control type="text" defaultValue={flyToWorkTicketInfo.jobTitle} readOnly />
          </Form.Group>
        </Form.Row>
        <hr></hr>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>
              Planned Assignment {flyToWorkTicketInfo?.direction?.toLowerCase() === 'arriving' ? 'Start' : 'End'} Date
            </Form.Label>
            <Datetime
              timeFormat={false}
              dateFormat={DATE_FORMAT_WEB}
              closeOnSelect
              utc={true}
              inputProps={{
                placeholder: DATE_FORMAT_WEB,
                disabled: true,
              }}
              value={DateTimeFormater(flyToWorkTicketInfo.plannedAssignmentStartDate, null, DATE_FORMAT_WEB)}
              onChange={(e) => {
                // console.log('e', e.format(DATE_FORMAT_ISO));
                handleUpdateFlightTicketInfo('plannedAssignmentStartDate', e.format(DATE_FORMAT_ISO));
              }}
              // isValidDate={(current) => {
              //   return current.isAfter(moment());
              // }}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Preferred Flight Date</Form.Label>
            <Datetime
              timeFormat={false}
              dateFormat={DATE_FORMAT_WEB}
              closeOnSelect
              utc={true}
              inputProps={{
                placeholder: DATE_FORMAT_WEB,
                disabled: isPreferredFlightDateDisabled(),
              }}
              value={DateTimeFormater(flyToWorkTicketInfo.preferredFlightDate, null, DATE_FORMAT_WEB)}
              onChange={(e) => {
                if (!e) return null;
                handleUpdateFlightTicketInfo('preferredFlightDate', e.format(DATE_FORMAT_ISO));
              }}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Type of flight</Form.Label>
            <Form.Control type="text" defaultValue={flyToWorkTicketInfo.typeOfFlight} readOnly />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Additional email to notification </Form.Label>
            <CreatableSelect
              isSearchable
              isClearable
              isMulti
              onChange={(e) => {
                if (!e) return null;
                // console.log('e', e);
                const emailRegex = /^[^\s@]+@(?:tui\.com|tui\.de)$/i;
                if (!e.every((email) => emailRegex.test(email.value))) {
                  var popEmail = e.pop();
                  toastr.warning('Error', `${popEmail.value} is invalid email, please review.`);
                  handleUpdateFlightTicketInfo('additionNotificationEmails', e);
                  return null;
                }
                handleUpdateFlightTicketInfo('additionNotificationEmails', e);
              }}
              value={flyToWorkTicketInfo.additionNotificationEmails}
              isDisabled={isOSFieldDisabled()}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Departure Airports</Form.Label>
            <Select
              options={mappedAirports}
              isSearchable
              isClearable
              isMulti
              onChange={(e) => {
                handleUpdateFlightTicketInfo(
                  'departureAirports',
                  e.map((x) => x.value)
                );
              }}
              value={
                // Ordena los valores seleccionados según el orden en que se guardaron
                mappedAirports
                  .filter((x) => flyToWorkTicketInfo.departureAirports?.includes(x.value) || null)
                  .sort(
                    (a, b) =>
                      flyToWorkTicketInfo.departureAirports.indexOf(a.value) -
                      flyToWorkTicketInfo.departureAirports.indexOf(b.value)
                  )
              }
              isDisabled={isArrivalDepartureAirportDisabled()}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Arrival Airports</Form.Label>
            <Select
              options={mappedAirports}
              isSearchable
              isClearable
              isMulti
              onChange={(e) => {
                handleUpdateFlightTicketInfo(
                  'arrivalAirports',
                  e.map((x) => x.value)
                );
              }}
              value={mappedAirports
                .filter((x) => flyToWorkTicketInfo.arrivalAirports?.includes(x.value) || null)
                .sort(
                  (a, b) =>
                    flyToWorkTicketInfo.arrivalAirports.indexOf(a.value) -
                    flyToWorkTicketInfo.arrivalAirports.indexOf(b.value)
                )}
              isDisabled={isArrivalDepartureAirportDisabled()}
            />
          </Form.Group>
        </Form.Row>
        <hr></hr>
        <Form.Row>
          <Form.Group as={Col}>
            <Row>
              <Col>
                <Form.Check
                  inline
                  label="Hotel Needed"
                  name="is_hotel_needed_checkbox"
                  type="checkbox"
                  id="hotelNeeded_checkbox"
                  value="isHotelNeeded"
                  checked={flyToWorkTicketInfo.isHotelNeeded}
                  onChange={(e) => {
                    handleUpdateFlightTicketInfo('isHotelNeeded', e.target.checked);
                  }}
                  disabled={isOSFieldDisabled()}
                />
              </Col>
              <Col>
                <Form.Check
                  inline
                  label="Book Return Flight"
                  name="is_book_return_flight_checkbox"
                  type="checkbox"
                  id="isBookReturnFlight_checkbox"
                  value="isBookReturnFlight"
                  checked={flyToWorkTicketInfo.isBookReturnFlight}
                  onChange={(e) => {
                    handleUpdateFlightTicketInfo('isBookReturnFlight', e.target.checked);
                    if (!e.target.checked) {
                      // reset values for BRF fields
                      handleUpdateFlightTicketInfo('brfReturnFlightDate', null);
                      handleUpdateFlightTicketInfo('brfDepartureAirport', null);
                      handleUpdateFlightTicketInfo('brfArrivalAirport', null);
                    }
                  }}
                  disabled={isOSFieldDisabled()}
                />
              </Col>
              <Col>
                <Form.Check
                  inline
                  label="Train ticket"
                  name="is_rail_and_fly_checkbox"
                  type="checkbox"
                  id="isRailAndFly_checkbox"
                  value="isRailAndFly"
                  checked={flyToWorkTicketInfo.isRailAndFly}
                  onChange={(e) => {
                    handleUpdateFlightTicketInfo('isRailAndFly', e.target.checked);
                    if (!e.target.checked) {
                      // reset home train station name
                      handleUpdateFlightTicketInfo('homeTrainStationName', null);
                    }
                  }}
                  disabled={isOSFieldDisabled()}
                />
              </Col>
              <Col>
                <Form.Check
                  inline
                  label="No travel arrangements needed"
                  name="no_flight_needed_checkbox"
                  type="checkbox"
                  id="noFlightneeded_checkbox"
                  value="noFlightNeeded"
                  checked={flyToWorkTicketInfo.noFlightNeeded}
                  onChange={(e) => {
                    handleUpdateFlightTicketInfo('noFlightNeeded', e.target.checked);
                  }}
                  disabled={isOSFieldDisabledNoTravelNeeded()}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '1%' }}>
              <Col>
                <Form.Check
                  inline
                  label="GreenLight"
                  name="isGreenLighted"
                  type="checkbox"
                  id="isGreenLight_checkbox"
                  value="isGreenLighted"
                  checked={flyToWorkTicketInfo.isGreenLighted}
                  onChange={(e) => {
                    handleUpdateFlightTicketInfo('isGreenLighted', e.target.checked);
                  }}
                  disabled={isGreenLightCheckBoxDisabled()}
                />
              </Col>
            </Row>
          </Form.Group>
        </Form.Row>
        <div hidden={!flyToWorkTicketInfo.isBookReturnFlight}>
          <hr></hr>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>(BRF) Return Flight Date</Form.Label>
              <Datetime
                timeFormat={false}
                dateFormat={DATE_FORMAT_WEB}
                closeOnSelect
                utc={true}
                inputProps={{ placeholder: DATE_FORMAT_WEB }}
                value={DateTimeFormater(flyToWorkTicketInfo.brfReturnFlightDate, null, DATE_FORMAT_WEB)}
                onChange={(e) => {
                  handleUpdateFlightTicketInfo('brfReturnFlightDate', DateTimeFormater(e, null, DATETIME_FORMAT_ISO));
                }}
                // isValidDate={(current) => {
                //   return current.isAfter(flyToWorkTicketInfo.ticketStartDate);
                // }}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>(BRF) Departure Airport</Form.Label>
              <Select
                options={mappedAirports}
                isSearchable
                isClearable
                value={mappedAirports.find((x) => x.value === flyToWorkTicketInfo?.brfDepartureAirport?.value) || null}
                onChange={(e) => {
                  handleUpdateFlightTicketInfo('brfDepartureAirport', e);
                }}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>(BRF) Arrival Airport</Form.Label>
              <Select
                options={mappedAirports}
                isSearchable
                isClearable
                value={mappedAirports.find((x) => x.value === flyToWorkTicketInfo?.brfArrivalAirport?.value) || null}
                onChange={(e) => {
                  handleUpdateFlightTicketInfo('brfArrivalAirport', e);
                }}
              />
            </Form.Group>
          </Form.Row>
        </div>
        <div hidden={!flyToWorkTicketInfo.isRailAndFly}>
          <hr></hr>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Train station name</Form.Label>
              <Form.Control
                type="text"
                defaultValue={flyToWorkTicketInfo.homeTrainStationName}
                onBlur={(e) => {
                  handleUpdateFlightTicketInfo('homeTrainStationName', e.target.value);
                }}
              />
            </Form.Group>
          </Form.Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default BusinessSupportCard;
