import { getEnvironmentVariablesHandler } from 'Handlers/EnvHandler';

export function getEnvironmentVariables() {
  function getTppWebVersionSuccess(environmentVariables) {
    return {
      type: 'GET_TPP_WEB_ENVIRONMENT_SUCCESS',
      data: { environmentVariables },
    };
  }

  return async function (dispatch) {
    dispatch(getTppWebVersionSuccess(getEnvironmentVariablesHandler()));
  };
}
