export const GET_SOURCEMARKETS_SUCCESS = 'GET_SOURCEMARKETS_SUCCESS';
export const GET_JOBFAMILIES_SUCCESS = 'GET_JOBFAMILIES_SUCCESS';
export const GET_POSITION_TYPES_SUCCESS = 'GET_POSITION_TYPES_SUCCESS';
export const GET_SEASONS_SUCCESS = 'GET_SEASONS_SUCCESS';
export const GET_DESTINATIONS_SUCCESS = 'GET_DESTINATIONS_SUCCESS';
export const GET_JOBTITLES_SUCCESS = 'GET_JOBTITLES_SUCCESS';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS';
export const GET_KEYWORDS_SUCCESS = 'GET_KEYWORDS_SUCCESS';
export const GET_MPLIDS_SUCCESS = 'GET_MPLIDS_SUCCESS';
export const GET_STAFF_STATUS_SUCCESS = 'GET_STAFF_STATUS_SUCCESS';
export const GET_STAFF_NATIONALITIES_SUCCESS = 'GET_STAFF_NATIONALITIES_SUCCESS';
export const GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS';
export const GET_LANGUAGE_LEVEL_SUCCESS = 'GET_LANGUAGE_LEVEL_SUCCESS';
