import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';

// this route is intentionality diferent to the res. with no new.js
import StatusNewView from 'Views/New/New';
// routes with index.js
import AllView from 'Views/All';
import PendingHrView from 'Views/PendingHr';
import PendingDesView from 'Views/PendingDes';
import PendingBttView from 'Views/PendingBtt';
import ConfirmedView from 'Views/Confirmed';

import TicketProfile from 'Views/TicketProfile/TicketProfile';

const TppSwitcher = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/health" exact component={() => <Redirect to="/new" />} />
        {/* Redirects */}
        <Route path="/" exact component={() => <Redirect to="/new" />} />

        <Route path="/all" exact component={() => <AllView />} />
        <Route path="/new" exact component={() => <StatusNewView />} />
        <Route path="/pendinghr" exact component={() => <PendingHrView />} />
        <Route path="/pendingbtt" exact component={() => <PendingBttView />} />
        <Route path="/pendingdes" exact component={() => <PendingDesView />} />
        <Route path="/confirmed" exact component={() => <ConfirmedView />} />

        <Route path="/:status/:ticketId" exact component={() => <TicketProfile />} />
      </Switch>
    </BrowserRouter>
  );
};

export default TppSwitcher;
