import { GET_USER_SUCCESS } from '../../../Constants/UserConstants';
import { authContext } from 'Configuration/AdalConfiguration';

export function getUser() {
  function getUserSuccess(user) {
    return {
      type: GET_USER_SUCCESS,
      data: { user },
    };
  }

  return async function (dispatch) {
    var { profile } = authContext.getCachedUser();
    const user = profile;
    // console.log('user', user);
    dispatch(getUserSuccess(user));
  };
}
