import React, { useEffect } from 'react';

import StatusConfirmedAgGridTable from 'Views/Confirmed/AgGridTable/StatusConfirmedAgGridTable';

const StatusConfirmed = () => {
  useEffect(() => {
    document.title = 'F2W - Confirmed';
  }, []);

  return (
    <React.Fragment>
      <h1>Confirmed</h1>
      <StatusConfirmedAgGridTable />
    </React.Fragment>
  );
};

export default StatusConfirmed;
