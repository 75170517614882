import React from 'react';
import { Button, Card, Table, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { DATETIME_FORMAT_WEB } from 'Constants/DateFormatConstants';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';
import RestClient from 'Services/RestClientService';
import { setLoader } from 'Redux/Actions/Loading/LoadingAction';

const AttachementCard = (props) => {
  const { flyToWorkTicketInfo, setRefreshComponent } = props;
  // console.log('flyToWorkTicketInfo', flyToWorkTicketInfo);
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);

  const handleDownloadAttachement = (attachmentId, attachementName) => {
    var payload = {
      id: flyToWorkTicketInfo.id,
      attachmentId: attachmentId,
    };

    RestClient.Download('attachment/download', payload, attachementName);
  };

  const handleDeleteAttachement = (attachmentId) => {
    var payload = {
      id: flyToWorkTicketInfo.id,
      attachmentId: attachmentId,
    };

    dispatch(setLoader(true));
    RestClient.Post('attachment/delete', payload)
      .then((response) => {
        // console.log('response', response);
        if (!response) return;

        if (response.ok) {
          toastr.success('Success', response.message);
        } else {
          toastr.error('Error', response.message);
        }
      })
      .finally(() => {
        dispatch(setLoader(false));
        setRefreshComponent(Math.random());
      });
  };

  const handleOnClickUploadFile = () => {
    hiddenFileInput.current.click();
  };
  const handleUploadFile = (file) => {
    // console.log('upload', file);
    if (!file) return;
    var formValues = [
      {
        key: 'flightTicketId',
        value: flyToWorkTicketInfo.id,
      },
    ];

    dispatch(setLoader(true));
    RestClient.Upload('attachment/upload', file, formValues)
      .then((response) => {
        // console.log('response', response);
        if (response.ok) {
          toastr.success('Success', 'File uploaded successfully');
        } else {
          toastr.error('Error', 'Error while uploading file');
        }
      })
      .finally(() => {
        dispatch(setLoader(false));
        hiddenFileInput.current.value = null;
        setRefreshComponent(Math.random());
      });
  };

  return (
    <>
      <Card style={{ marginTop: '1%' }}>
        <Card.Header>
          <i className="fa fa-file mr-2"></i>Attachements
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Uploaded by</th>
                <th>Created date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {flyToWorkTicketInfo?.attachments?.map((attachement, index) => {
                return (
                  <tr key={index}>
                    <td>{attachement.name}</td>
                    <td>{attachement.createdBy}</td>
                    <td>{DateTimeFormater(attachement.created, null, DATETIME_FORMAT_WEB)}</td>
                    <td>
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => handleDownloadAttachement(attachement.id, attachement.name)}
                      >
                        Download
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        className="ml-2"
                        onClick={() => handleDeleteAttachement(attachement.id)}
                      >
                        Remove
                      </Button>
                    </td>
                  </tr>
                );
              })}
              {!flyToWorkTicketInfo?.attachments && (
                <tr>
                  <td colSpan="5" align="center">
                    No attachements found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer>
          <Row style={{ display: 'flex' }}>
            <Button onClick={(e) => handleOnClickUploadFile(e)} variant="warning" style={{ margin: '0 5px' }} size="sm">
              Upload file{' '}
            </Button>
            <input
              ref={hiddenFileInput}
              onChange={(e) => handleUploadFile(e.target.files[0])}
              type="file"
              name="file"
              id="file"
              hidden
            />
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
};

export default AttachementCard;
