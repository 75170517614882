import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';

import Header from 'Layout/Components/Header';
import Footer from 'Layout/Components/Footer';
import Loader from 'Components/Loader';
import { getEnvironmentVariables } from 'Redux/Actions/Environment/EnvironmentAction';

import 'Layout/Layout.css';
import { Container } from 'react-bootstrap';

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  dispatch(getEnvironmentVariables());
  const envVariables = useSelector((state) => state.environment);

  return (
    <div className="layout">
      <Loader />
      <Header />
      <Container fluid className="content">
        <main>{children}</main>
      </Container>
      <ReduxToastr
        timeOut={4000}
        position="bottom-right"
        transitionIn="bounceIn"
        transitionOut="bounceOut"
        preventDuplicates={true}
        progressBar
      />
      <Footer envVariables={envVariables} />
    </div>
  );
};

export default Layout;
