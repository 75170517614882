import React, { useEffect } from 'react';

import StatusPendingBttAgGridTable from 'Views/PendingBtt/AgGridTable/StatusPendingBttAgGridTable';

const StatusPendingBtt = () => {
  useEffect(() => {
    document.title = 'F2W - PendingBtt';
  }, []);

  return (
    <React.Fragment>
      <h1>Pending BTT</h1>
      <StatusPendingBttAgGridTable />
    </React.Fragment>
  );
};

export default StatusPendingBtt;
