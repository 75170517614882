import axios from 'axios';
import download from 'downloadjs';
import { toastr } from 'react-redux-toastr';

import { adalApiFetch } from 'Configuration/AdalConfiguration';
import { getEnvironmentVariablesHandler } from 'Handlers/EnvHandler';

const baseUrl = `${getEnvironmentVariablesHandler().apiBaseUrl}`;
const baseGpxUrl = `${getEnvironmentVariablesHandler().apiGpxBaseUrl}`;

class RestClient {
  static async Get(url) {
    var axiosInstance = axios.create({
      baseURL: baseUrl,
    });
    try {
      let response = await adalApiFetch(axiosInstance, baseUrl + url, {});
      return response?.data;
    } catch (err) {
      console.error(err.response?.data?.message ?? err);
      toastr.error('Error', `An unexpected error has occured: ${err.response?.data?.message ?? err}.`);
    }
  }

  static async GetFromGpx(url) {
    var axiosInstance = axios.create({
      baseURL: baseGpxUrl,
    });
    try {
      let response = await adalApiFetch(axiosInstance, baseGpxUrl + url, {});
      return response?.data;
    } catch (err) {
      console.error(err.response?.data?.message ?? err);
      toastr.error('Error', `An unexpected error has occured: ${err.response?.data?.message ?? err}.`);
    }
  }

  static async Post(url, data) {
    var axiosInstance = axios.create({
      baseURL: baseUrl,
    });
    try {
      let settings = {
        headers: {
          Accept: 'Application/json',
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: '',
        },
        data: JSON.stringify(data),
        method: 'POST',
      };

      let response = await adalApiFetch(axiosInstance, baseUrl + url, settings);

      return response?.data;
    } catch (err) {
      console.error(err.response?.data?.message ?? err);
      toastr.error('Error', `An unexpected error has occured: ${err.response?.data?.message ?? err}.`);
    }
  }

  static async Delete(url, data) {
    var axiosInstance = axios.create({
      baseURL: baseUrl,
    });
    try {
      let settings = {
        headers: {
          Accept: 'Application/json',
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: '',
        },
        data: JSON.stringify(data),
        method: 'DELETE',
      };

      let response = await adalApiFetch(axiosInstance, baseUrl + url, settings);

      return response?.data;
    } catch (err) {
      console.error(err.response?.data?.message ?? err);
      toastr.error('Error', `An unexpected error has occured: ${err.response?.data?.message ?? err}.`);
    }
  }

  static async Put(url, data) {
    var axiosInstance = axios.create({
      baseURL: baseUrl,
    });
    try {
      let settings = {
        headers: {
          Accept: 'Application/json',
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: '',
        },
        data: JSON.stringify(data),
        method: 'PUT',
      };

      let response = await adalApiFetch(axiosInstance, baseUrl + url, settings);

      return response.data;
    } catch (err) {
      console.error(err.response?.data?.message ?? err);
      toastr.error('Error', `An unexpected error has occured: ${err.response?.data?.message ?? err}.`);
    }
  }

  static async Download(url, data, fileName) {
    var axiosInstance = axios.create({
      baseURL: baseUrl,
    });

    try {
      let settings = {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: '',
        },
        data: JSON.stringify(data),
        method: 'POST',
        responseType: 'arraybuffer', // Cambiado a 'arraybuffer' para manejar datos binarios
      };

      let response = await adalApiFetch(axiosInstance, baseUrl + url, settings);

      if (response.data.byteLength === 0) {
        return {
          message: 'Parece que no tienes permiso para realizar esta acción, por favor contacta a tu gerente.',
        };
      }

      // Crear un Blob con el tipo MIME correcto para un archivo .xlsb
      const blob = new Blob([response.data], { type: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12' });

      // Utilizar una función de descarga que pueda manejar objetos Blob
      return download(blob, fileName);
    } catch (err) {
      console.error(err.response?.data?.message ?? err);
      toastr.error('Error', `Ha ocurrido un error inesperado: ${err.response?.data?.message ?? err}.`);
    }
  }

  static async Upload(url, file, data) {
    try {
      // File
      var formData = new FormData();
      formData.append('file', file);

      // Data
      for (var item of data) {
        formData.append(item.key, item.value);
      }

      var axiosInstance = axios.create({
        baseURL: baseUrl,
      });

      const options = {
        method: 'POST',
        data: formData,
        config: {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      };

      let response = await adalApiFetch(axiosInstance, baseUrl + url, options);
      return response.data;
    } catch (err) {
      console.error(err.response?.data?.message ?? err);
      toastr.error('Error', `An unexpected error has occured: ${err.response?.data?.message ?? err}.`);
    }
  }
}

export default RestClient;
