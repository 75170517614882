export const DS_F2W_Business_Travel_Team = 'DS_F2W_Business_Travel_Team';
export const DS_F2W_Edit = 'DS_F2W_Edit';
export const DS_F2W_HR_Team = 'DS_F2W_HR_Team';

export const DS_GPX_Edit = 'DS_GPX_Edit';
export const DS_GPX_Read_Only = 'DS_GPX_Read_Only';

export const DS_MTP_Edit = 'DS_MTP_Edit';
export const DS_MTP_TUI_DX_Senior_Managers = 'DS_MTP_TUI_DX_Senior_Managers';
export const DS_MTP_TUI_DX_Team_Managers = 'DS_MTP_TUI_DX_Team_Managers';

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
