export const setStatusColor = (status) => {
  switch (status?.toLowerCase().trim()) {
    case 'new':
      return 'primary';
    case 'new recruit':
    case 'existing':
    case 'active':
      return 'success';
    case 'ready to communicate':
    case 'pending':
    case 'modified':
      return 'warning';
    case 'want 2nd offer':
    case 'want 2nd offer to communicate':
    case 'want 2nd offer accepted':
      return 'info';
    case 'inactive':
    case 'resigned':
    case 'leave of absence':
    case 'cancelled':
      return 'danger';
    default:
      return 'dark';
  }
};
