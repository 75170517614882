import React from 'react';

import StatusStaffTabsRow from 'Components/StatusStaffTabsRow';
import AttachementsCard from 'Views/TicketProfile/Components/AttachementsCard';

const AttachementsTab = (props) => {
  const { flyToWorkTicketInfo, setRefreshComponent } = props;

  return (
    <React.Fragment>
      <StatusStaffTabsRow flyToWorkTicketInfo={flyToWorkTicketInfo} />
      <hr style={{ marginTop: 0 }} />
      <AttachementsCard flyToWorkTicketInfo={flyToWorkTicketInfo} setRefreshComponent={setRefreshComponent} />
    </React.Fragment>
  );
};

export default AttachementsTab;
