import React from 'react';
import { Breadcrumb, Row, Col, Badge } from 'react-bootstrap';

import { setStatusColor } from 'Handlers/StatusColorHandler';

const StatusStaffTabsRow = (props) => {
  const { flyToWorkTicketInfo } = props;
  // console.log('status', flyToWorkTicketInfo.status);
  // console.log('confirmedStatus', flyToWorkTicketInfo.confirmedStatus);

  return (
    <React.Fragment>
      <Row style={{ paddingTop: '0.5%' }}>
        <Col lg={8} style={{ paddingBottom: '0.5%' }}>
          Status : <Badge variant={setStatusColor(flyToWorkTicketInfo.status)}> {flyToWorkTicketInfo.status}</Badge>{' '}
          <Badge variant={setStatusColor(flyToWorkTicketInfo.confirmedStatus)}>
            {flyToWorkTicketInfo.confirmedStatus}
          </Badge>
          <br></br>
          Direction : <Badge variant="secondary">{flyToWorkTicketInfo.direction}</Badge>
          <br></br>
          Type of flight : <Badge variant="secondary">{flyToWorkTicketInfo.typeOfFlight}</Badge>
        </Col>
        <Col lg={4} className="justify-content-end">
          <Breadcrumb style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Breadcrumb.Item href="/">F2w</Breadcrumb.Item>
            <Breadcrumb.Item href="/staff">Ticket</Breadcrumb.Item>
            <Breadcrumb.Item active>
              {flyToWorkTicketInfo.lastName}, {flyToWorkTicketInfo.firstName}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default StatusStaffTabsRow;
