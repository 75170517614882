import RestClient from 'Services/RestClientService';
import {
  GET_SOURCEMARKETS_SUCCESS,
  GET_JOBFAMILIES_SUCCESS,
  GET_POSITION_TYPES_SUCCESS,
  GET_SEASONS_SUCCESS,
  GET_DESTINATIONS_SUCCESS,
  GET_JOBTITLES_SUCCESS,
  GET_COUNTRIES_SUCCESS,
  GET_REGIONS_SUCCESS,
  GET_KEYWORDS_SUCCESS,
  GET_MPLIDS_SUCCESS,
  GET_STAFF_STATUS_SUCCESS,
  GET_STAFF_NATIONALITIES_SUCCESS,
  GET_LANGUAGES_SUCCESS,
  GET_LANGUAGE_LEVEL_SUCCESS,
} from '../../../Constants/MasterDataConstants';

export function getSourceMarkets() {
  const getSourceMarketSuccess = (sourceMarkets) => {
    return {
      type: GET_SOURCEMARKETS_SUCCESS,
      data: { sourceMarkets },
    };
  };

  return async function (dispatch) {
    const sourceMarkets = await RestClient.GetFromGpx('masterdata/source-market');
    const sourceMarketsMaped = Object.keys(sourceMarkets.masterDataList ?? []).map((key) => {
      return { value: sourceMarkets.masterDataList[key], label: sourceMarkets.masterDataList[key] };
    });
    dispatch(getSourceMarketSuccess(sourceMarketsMaped));
  };
}

export function getJobFamilies() {
  const getjobFamiliesSuccess = (jobFamilies) => {
    return {
      type: GET_JOBFAMILIES_SUCCESS,
      data: { jobFamilies },
    };
  };

  return async function (dispatch) {
    const jobFamilies = await RestClient.GetFromGpx('masterdata/job-family');
    const jobFamiliesMaped = Object.keys(jobFamilies.masterDataList ?? []).map((key) => {
      return { value: jobFamilies.masterDataList[key], label: jobFamilies.masterDataList[key] };
    });
    dispatch(getjobFamiliesSuccess(jobFamiliesMaped));
  };
}

export function getJobTitles() {
  const getjobTitlesSuccess = (jobTitles) => {
    return {
      type: GET_JOBTITLES_SUCCESS,
      data: { jobTitles },
    };
  };

  return async function (dispatch) {
    const jobTitles = await RestClient.GetFromGpx('masterdata/job-title');
    const jobTitlesMaped = Object.keys(jobTitles.masterDataList ?? []).map((key) => {
      return { value: jobTitles.masterDataList[key], label: jobTitles.masterDataList[key] };
    });
    jobTitlesMaped.push({ label: 'CT 01 - TBFT (TUI Blue for Two)', value: 'CT 01 - TBFT (TUI Blue for Two)' });
    jobTitlesMaped.push({
      label: 'CT 02 - TBFF (TUI Blue for Families)',
      value: 'CT 02 - TBFF (TUI Blue for Families)',
    });
    jobTitlesMaped.push({
      label: 'CT 03 - TBSEN (TUI Blue Sensatori)',
      value: 'CT 03 - TBSEN (TUI Blue Sensatori)',
    });
    jobTitlesMaped.push({ label: 'CT 04 - TBFA (TUI Blue for All)', value: 'CT 04 - TBFA (TUI Blue for All)' });
    jobTitlesMaped.push({ label: 'CT 05 - TKC (TUI Kids Club)', value: 'CT 05 - TKC (TUI Kids Club)' });
    jobTitlesMaped.push({ label: 'CT 06 - TUI SUN (TUI Suneo)', value: 'CT 06 - TUI SUN (TUI Suneo)' });
    jobTitlesMaped.push({ label: 'CT 07 - HV (Holiday Village)', value: 'CT 07 - HV (Holiday Village)' });
    jobTitlesMaped.push({ label: 'CT 08 - Shared Costs', value: 'CT 08 - Shared Costs' });
    dispatch(getjobTitlesSuccess(jobTitlesMaped));
  };
}

export function getPositionTypes() {
  const getPositionTypesSuccess = (positionTypes) => {
    return {
      type: GET_POSITION_TYPES_SUCCESS,
      data: { positionTypes },
    };
  };

  return async function (dispatch) {
    const positionTypes = await RestClient.GetFromGpx('masterdata/mpl-position-type');
    const positionTypesMaped = Object.keys(positionTypes.masterDataList ?? []).map((key) => {
      return { value: positionTypes.masterDataList[key], label: positionTypes.masterDataList[key] };
    });
    dispatch(getPositionTypesSuccess(positionTypesMaped));
  };
}

export function getSeaons() {
  const getSeaonsSuccess = (seasons) => {
    return {
      type: GET_SEASONS_SUCCESS,
      data: { seasons },
    };
  };

  return async function (dispatch) {
    const seasons = await RestClient.GetFromGpx('masterdata/season');
    // console.log('seasons', seasons);
    const seasonsMaped = Object.keys(seasons.masterDataList ?? []).map((key) => {
      return { value: seasons.masterDataList[key], label: seasons.masterDataList[key] };
    });
    dispatch(getSeaonsSuccess(seasonsMaped));
  };
}

export function getCountries() {
  const getCountriesSuccess = (countries) => {
    return {
      type: GET_COUNTRIES_SUCCESS,
      data: { countries },
    };
  };

  return async function (dispatch) {
    const countries = await RestClient.GetFromGpx('masterdata/country');
    const countriesMaped = Object.keys(countries.masterDataList ?? []).map((key) => {
      return { value: countries.masterDataList[key], label: countries.masterDataList[key] };
    });
    dispatch(getCountriesSuccess(countriesMaped));
  };
}

export function getRegions() {
  const getRegionsSuccess = (regions) => {
    return {
      type: GET_REGIONS_SUCCESS,
      data: { regions },
    };
  };

  return async function (dispatch) {
    const regions = await RestClient.GetFromGpx('masterdata/region');
    const countriesMaped = Object.keys(regions.masterDataList ?? []).map((key) => {
      return { value: regions.masterDataList[key], label: regions.masterDataList[key] };
    });
    dispatch(getRegionsSuccess(countriesMaped));
  };
}

export function getDestinations() {
  const getDestinationsSuccess = (destinations) => {
    return {
      type: GET_DESTINATIONS_SUCCESS,
      data: { destinations },
    };
  };

  return async function (dispatch) {
    const destinations = await RestClient.GetFromGpx('masterdata/destination');
    const destinationsMaped = Object.keys(destinations.masterDataList ?? []).map((key) => {
      return { value: destinations.masterDataList[key], label: destinations.masterDataList[key] };
    });
    destinationsMaped.push({ label: 'Hanover', value: 'Hanover' });
    dispatch(getDestinationsSuccess(destinationsMaped));
  };
}

export function getKeyWords() {
  const getKeyWordsSuccess = (keywords) => {
    return {
      type: GET_KEYWORDS_SUCCESS,
      data: { keywords },
    };
  };

  return async function (dispatch) {
    const keywords = await RestClient.GetFromGpx('setting/keywords');
    dispatch(getKeyWordsSuccess(keywords));
  };
}

export function getMplids() {
  const getMplidsSuccess = (mplids) => {
    return {
      type: GET_MPLIDS_SUCCESS,
      data: { mplids },
    };
  };

  return async function (dispatch) {
    const mplids = await RestClient.GetFromGpx('masterdata/mplid');
    const mplidsMaped = Object.keys(mplids.masterDataList ?? []).map((key) => {
      return { value: mplids.masterDataList[key], label: mplids.masterDataList[key] };
    });
    dispatch(getMplidsSuccess(mplidsMaped));
  };
}

export function getStaffStatus() {
  const getStaffStatusSuccess = (staffStatus) => {
    return {
      type: GET_STAFF_STATUS_SUCCESS,
      data: { staffStatus },
    };
  };

  return async function (dispatch) {
    const staffStatus = await RestClient.GetFromGpx('masterdata/staff-status');
    const staffStatusMaped = Object.keys(staffStatus.masterDataList ?? []).map((key) => {
      return { value: staffStatus.masterDataList[key], label: staffStatus.masterDataList[key] };
    });
    dispatch(getStaffStatusSuccess(staffStatusMaped));
  };
}

export function getStaffNationalities() {
  const getStaffStatusSuccess = (staffNationalities) => {
    return {
      type: GET_STAFF_NATIONALITIES_SUCCESS,
      data: { staffNationalities },
    };
  };

  return async function (dispatch) {
    const staffNationalities = await RestClient.GetFromGpx('masterdata/staff-nationalies');
    // console.log('staff-nationalies', staffNationalities);
    const staffNationalitiesMaped = Object.keys(staffNationalities.masterDataList ?? []).map((key) => {
      return { value: staffNationalities.masterDataList[key], label: staffNationalities.masterDataList[key] };
    });
    dispatch(getStaffStatusSuccess(staffNationalitiesMaped));
  };
}

export function getLanguages() {
  const getLanguagesSuccess = (languages) => {
    return {
      type: GET_LANGUAGES_SUCCESS,
      data: { languages },
    };
  };

  return async function (dispatch) {
    const languages = await RestClient.GetFromGpx('masterdata/language');
    // console.log('language', languages);
    const languagesMaped = Object.keys(languages.masterDataListTupple ?? []).map((key) => {
      return { value: languages.masterDataListTupple[key][0], label: languages.masterDataListTupple[key][1] };
    });
    dispatch(getLanguagesSuccess(languagesMaped));
  };
}

export function getLanguageLevel() {
  const getLanguageLevelSuccess = (languageLevels) => {
    return {
      type: GET_LANGUAGE_LEVEL_SUCCESS,
      data: { languageLevels },
    };
  };

  return async function (dispatch) {
    const languageLevels = await RestClient.GetFromGpx('masterdata/language-level');
    // console.log('language-level', languageLevels);
    const languageLevelMaped = Object.keys(languageLevels.masterDataListTupple ?? []).map((key) => {
      return { value: languageLevels.masterDataListTupple[key][0], label: languageLevels.masterDataListTupple[key][1] };
    });
    dispatch(getLanguageLevelSuccess(languageLevelMaped));
  };
}
