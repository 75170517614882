import React from 'react';
import { Card, Form, Col } from 'react-bootstrap';

const TicketIdentifiersCard = (props) => {
  const { flyToWorkTicketInfo } = props;
  // console.log('flyToWorkTicketInfo', flyToWorkTicketInfo);

  return (
    <Card className="staff-profile-card">
      <Card.Header>
        <i className="fa fa-id-card mr-2"></i> Identifiers
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Ticket id</Form.Label>
              <Form.Control type="text" defaultValue={flyToWorkTicketInfo.id} readOnly />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Staff id</Form.Label>
              <Form.Control type="text" defaultValue={flyToWorkTicketInfo.originalStaffId} readOnly />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Mplid</Form.Label>
              <Form.Control type="text" defaultValue={flyToWorkTicketInfo.mplid} readOnly />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Position assign id</Form.Label>
              <Form.Control type="text" defaultValue={flyToWorkTicketInfo.positionAssignId} readOnly />
            </Form.Group>
          </Form.Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default TicketIdentifiersCard;
