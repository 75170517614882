import React, { useEffect, useState } from 'react';
import { Card, Form, Col, Row, Button } from 'react-bootstrap';
import Datetime from 'react-datetime';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';

import { DATETIME_FORMAT_ISO, DATE_FORMAT_WEB } from 'Constants/DateFormatConstants';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';
import airports from 'Constants/airports.json';
import RestClient from 'Services/RestClientService';
import { setLoader } from 'Redux/Actions/Loading/LoadingAction';
import { DS_F2W_Business_Travel_Team } from 'Constants/F2wRolesConstants';
import { costCentreOptions } from 'Constants/F2wCecos';

const FlightCard = (props) => {
  const { flyToWorkTicketInfo, setRefreshComponent, flight, setShowAddFlightModal, showCloseButtonOnModal } = props;
  const dispatch = useDispatch();
  // console.log('flyToWorkTicketInfo', flyToWorkTicketInfo);
  // console.log('flight', flight);

  const user = useSelector((state) => state.currentUser);
  const userRoles = user?.roles;

  const currencySelectOptions = [
    { label: '€', value: 'EUR' },
    { label: '£', value: 'GBP' },
  ];

  const validLuggageSelectOptions = [
    { label: '1 x 20 Kgs + Hand luggage', value: '1 x 20 Kgs + Hand luggage' },
    {
      label: '1 x 20 Kgs + Hand luggage (XBAG to be added at check-in)',
      value: '1 x 20 Kgs + Hand luggage (XBAG to be added at check-in)',
    },
    { label: '2 x 20 Kgs + Priority & Hand luggage', value: '2 x 20 Kgs + Priority & Hand luggage' },
    { label: '2 x 20 Kgs + Hand luggage', value: '2 x 20 Kgs + Hand luggage' },
    { label: '2 x 20 Kgs - no written confirmation', value: '2 x 20 Kgs - no written confirmation' },
    { label: '30 Kgs in total + Hand luggage', value: '30 Kgs in total + Hand luggage' },
  ];
  const [currentSelectedFlight, setCurrentSelectedFlight] = useState({});
  const [validationFlightFormErrors, setValidationFlightFormErrors] = useState({});

  const handleAddFlightToTicket = () => {
    console.log('currentSelectedFlight', currentSelectedFlight);
    console.log('flyToWorkTicketInfo', flyToWorkTicketInfo);
    const errors = {};
    const flightFields = Object.keys(currentSelectedFlight);

    for (let i = 0; i < flightFields.length; i++) {
      const field = flightFields[i];
      if (field === 'id') continue;
      if (field === 'xbagCost') continue;
      if (field === 'flightCost') continue;
      if (field === 'hotelCost') continue;
      if (field === 'flightArrivalTime') continue;
      if (field === 'brfReturnFlightDate') continue;
      if (field === 'brfDepartureAirport') continue;
      if (field === 'brfArrivalAirport') continue;
      if (field === 'otherTransportCost') continue;
      if (field === 'hotelCheckOutDate') continue;

      if (
        currentSelectedFlight[field] === null ||
        currentSelectedFlight[field] === '' ||
        !currentSelectedFlight[field]
      ) {
        errors[field] = `Please fill in the ${field}`;
      }
    }

    // no comporbar errores si isHotelNeeded es falso
    if (!flyToWorkTicketInfo.isHotelNeeded) {
      delete errors.hotelName;
      delete errors.hotelCheckInDate;
      delete errors.hotelCost;
    }

    // no comporbar errores si isBookReturnFlight es falso
    if (!flyToWorkTicketInfo.isBookReturnFlight) {
      delete errors.brfReturnFlightDate;
      delete errors.brfDepartureAirport;
      delete errors.brfArrivalAirport;
    }

    // console.log('errors', errors);
    if (Object.keys(errors).length > 0) {
      setValidationFlightFormErrors(errors);
      return;
    }

    // enviar la informacion de currentSelectedFlight
    var payload = {
      flyToWorkTicketId: flyToWorkTicketInfo.id,
      confirmedFlightDate: currentSelectedFlight.confirmedFlightDate,
      bookingReference: currentSelectedFlight.bookingReference,
      flightNumber: currentSelectedFlight.flightNumber,
      departureAirport: currentSelectedFlight.departureAirport,
      flightDepartureTime: currentSelectedFlight.flightDepartureTime,
      arrivalAirport: currentSelectedFlight.arrivalAirport,
      flightArrivalTime: currentSelectedFlight.flightArrivalTime,
      travelType: currentSelectedFlight.travelType,
      paymentMethod: currentSelectedFlight.paymentMethod,
      luggage: currentSelectedFlight.luggage,
      xbagCost: currentSelectedFlight.xbagCost,
      flightCost: currentSelectedFlight.flightCost,
      hotelCost: currentSelectedFlight.hotelCost,
      costCentre: currentSelectedFlight.costCentre,
      otherTransportCost: currentSelectedFlight.otherTransportCost,
      currency: currentSelectedFlight.currency,
      hotelName: currentSelectedFlight.hotelName,
      hotelCheckInDate: currentSelectedFlight.hotelCheckInDate,
      hotelCheckOutDate: currentSelectedFlight.hotelCheckOutDate,
      brfReturnFlightDate: currentSelectedFlight.brfReturnFlightDate,
      brfDepartureAirport: currentSelectedFlight.brfDepartureAirport,
      brfArrivalAirport: currentSelectedFlight.brfArrivalAirport,
    };

    dispatch(setLoader(true));
    setShowAddFlightModal(false);
    RestClient.Post(`flight/insert/${flyToWorkTicketInfo.id}`, payload)
      .then((response) => {
        // console.log('response', response);
        if (response && response.ok) {
          toastr.success('Success', response.message);
        } else {
          toastr.error('Error', response.message);
        }
      })
      .finally(() => {
        dispatch(setLoader(false));
        setRefreshComponent(Math.random());
      });
  };

  const handleRemoveFlightFromTicket = () => {
    dispatch(setLoader(true));
    var payload = {
      id: currentSelectedFlight.id,
    };

    RestClient.Delete(`flight/delete/${flyToWorkTicketInfo.id}`, payload)
      .then((response) => {
        // console.log('response', response);
        if (response.ok) {
          toastr.success('Success', response.message);
        } else {
          toastr.error('Error', response.message);
        }
      })
      .finally(() => {
        dispatch(setLoader(false));
        setRefreshComponent(Math.random());
      });
  };

  const mappedAirports = airports.map((airport) => {
    return {
      value: airport.iata_code,
      label: `${airport.iata_code} - ${airport.name}`,
    };
  });
  const mappedTravelTypes = [
    { label: 'Charter', value: 'Charter' },
    { label: 'Scheduled', value: 'Scheduled' },
  ];
  const mappedPaymentMethods = [
    { label: 'CC', value: 'CC' },
    { label: 'Charter', value: 'Charter' },
    { label: 'TravelPerk', value: 'TravelPerk' },
  ];

  const totalCost = (
    Number(currentSelectedFlight.xbagCost) +
    Number(currentSelectedFlight.flightCost) +
    Number(currentSelectedFlight.otherTransportCost) +
    Number(currentSelectedFlight.hotelCost)
  ).toFixed(2);
  const arrivalAirports = currentSelectedFlight.arrivalAirport?.split(',');
  const departureAirports = currentSelectedFlight.departureAirport?.split(',');

  useEffect(() => {
    // TODO : do this in migration part
    // create a regex that detect if is a hour string
    // then is hour detected parse to have the correct format as a dateTime
    // const hourRegex = new RegExp('^([01]?[0-9]|2[0-3]):[0-5][0-9]$');
    // if (flight.flightDepartureTime && hourRegex.test(flight.flightDepartureTime)) {
    //   let isoDateDeparture = DateTimeFormater(flight.confirmedFlightDate, DATE_FORMAT_WEB, DATE_FORMAT_ISO);
    //   let flightDepartureTime = isoDateDeparture + 'T' + flight.flightDepartureTime + ':00.000Z';
    //   flight.flightDepartureTime = flightDepartureTime;
    // }
    // let flightArrivalTime = '';
    // if (flight.flightArrivalTime && hourRegex.test(flight.flightArrivalTime)) {
    //   let isoDateArrival = DateTimeFormater(flight.confirmedFlightDate, DATE_FORMAT_WEB, DATE_FORMAT_ISO);
    //   flightArrivalTime = isoDateArrival + 'T' + flight.flightArrivalTime + ':00.000Z';
    //   flight.flightArrivalTime = flightArrivalTime;
    // }
    // ---

    console.log('flight', flight);

    setCurrentSelectedFlight({
      id: flight.id,
      confirmedFlightDate: flight.confirmedFlightDate,
      bookingReference: flight.bookingReference,
      flightNumber: flight.flightNumber,
      departureAirport: flight.departureAirport,
      flightDepartureTime: flight.flightDepartureTime,
      arrivalAirport: flight.arrivalAirport,
      flightArrivalTime: flight.flightArrivalTime,
      travelType: flight.travelType,
      paymentMethod: flight.paymentMethod,
      luggage: flight.luggage || '',
      xbagCost: flight.xbagCost || 0,
      flightCost: flight.flightCost || 0,
      hotelCost: flight.hotelCost,
      costCentre: flight.costCentre || '',
      otherTransportCost: flight.otherTransportCost || '',
      currency: flight.currency,
      // show and hide extra fields about hotel
      hotelName: flight.hotelName,
      hotelCheckInDate: flight.hotelCheckInDate,
      hotelCheckOutDate: flight.hotelCheckOutDate,
      // show and hide extra fields about Book Return Flight
      brfReturnFlightDate: flight.brfReturnFlightDate,
      brfDepartureAirport: flight.brfDepartureAirport,
      brfArrivalAirport: flight.brfArrivalAirport,
    });
  }, [flight]);

  return (
    <Card className="staff-profile-card">
      <Card.Header hidden={!currentSelectedFlight.id}>
        <i className="fa fa-plane mr-2"></i> Flight id : {currentSelectedFlight.id}
      </Card.Header>
      <Card.Body>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Confirmed Flight Date</Form.Label>
            <Datetime
              timeFormat={false}
              dateFormat={DATE_FORMAT_WEB}
              closeOnSelect
              utc={true}
              inputProps={{ placeholder: DATE_FORMAT_WEB, disabled: !userRoles.includes(DS_F2W_Business_Travel_Team) }}
              value={DateTimeFormater(currentSelectedFlight.confirmedFlightDate, null, DATE_FORMAT_WEB)}
              onChange={(e) => {
                setValidationFlightFormErrors({});
                setCurrentSelectedFlight({
                  ...currentSelectedFlight,
                  confirmedFlightDate: DateTimeFormater(e, null, DATETIME_FORMAT_ISO),
                });
              }}
              // isValidDate={(current) => {
              //   return current.isAfter(flyToWorkTicketInfo.ticketStartDate);
              // }}
            />
            {validationFlightFormErrors.confirmedFlightDate && (
              <small className="form-text-red">{validationFlightFormErrors.confirmedFlightDate}</small>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Booking Reference</Form.Label>
            <Form.Control
              type="text"
              value={currentSelectedFlight?.bookingReference || ''}
              onChange={(e) => {
                setValidationFlightFormErrors({});
                setCurrentSelectedFlight({
                  ...currentSelectedFlight,
                  bookingReference: e.target.value,
                });
              }}
              disabled={!userRoles.includes(DS_F2W_Business_Travel_Team)}
            />
            {validationFlightFormErrors.bookingReference && (
              <small className="form-text-red">{validationFlightFormErrors.bookingReference}</small>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Flight Number</Form.Label>
            <Form.Control
              type="text"
              defaultValue={currentSelectedFlight.flightNumber}
              onChange={(e) => {
                setValidationFlightFormErrors({});
                setCurrentSelectedFlight({
                  ...currentSelectedFlight,
                  flightNumber: e.target.value,
                });
              }}
              disabled={!userRoles.includes(DS_F2W_Business_Travel_Team)}
            />
            {validationFlightFormErrors.flightNumber && (
              <small className="form-text-red">{validationFlightFormErrors.flightNumber}</small>
            )}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Departure Airport</Form.Label>
            <Select
              isDisabled={!userRoles.includes(DS_F2W_Business_Travel_Team)}
              options={mappedAirports}
              isSearchable
              isClearable
              value={departureAirports?.map((airport) => {
                return mappedAirports.find((x) => x.value === airport);
              })}
              onChange={(e) => {
                setValidationFlightFormErrors({});
                if (!e || !e.value) {
                  setCurrentSelectedFlight({
                    ...currentSelectedFlight,
                    departureAirport: '',
                  });
                  return;
                }
                setCurrentSelectedFlight({
                  ...currentSelectedFlight,
                  departureAirport: e.value,
                });
              }}
              // isValidDate={(current) => {
              //   return current.isAfter(flyToWorkTicketInfo.ticketStartDate);
              // }}
            />
            {validationFlightFormErrors.departureAirport && (
              <small className="form-text-red">{validationFlightFormErrors.departureAirport}</small>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Flight Departure Time</Form.Label>
            <Form.Control
              type="time"
              defaultValue={currentSelectedFlight.flightDepartureTime}
              onChange={(e) => {
                setValidationFlightFormErrors({});
                setCurrentSelectedFlight({
                  ...currentSelectedFlight,
                  flightDepartureTime: e.target.value,
                });
              }}
              disabled={!userRoles.includes(DS_F2W_Business_Travel_Team)}
            />

            {validationFlightFormErrors.flightDepartureTime && (
              <small className="form-text-red">{validationFlightFormErrors.flightDepartureTime}</small>
            )}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Arrival Airport</Form.Label>
            <Select
              isDisabled={!userRoles.includes(DS_F2W_Business_Travel_Team)}
              options={mappedAirports}
              isSearchable
              isClearable
              value={arrivalAirports?.map((airport) => {
                return mappedAirports.find((x) => x.value === airport);
              })}
              onChange={(e) => {
                setValidationFlightFormErrors({});
                if (!e || !e.value) {
                  setCurrentSelectedFlight({
                    ...currentSelectedFlight,
                    arrivalAirport: '',
                  });
                  return;
                }
                setCurrentSelectedFlight({
                  ...currentSelectedFlight,
                  arrivalAirport: e.value,
                });
              }}
            />
            {validationFlightFormErrors.arrivalAirport && (
              <small className="form-text-red">{validationFlightFormErrors.arrivalAirport}</small>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Flight Arrival Time</Form.Label>
            <Form.Control
              type="time"
              defaultValue={currentSelectedFlight.flightArrivalTime}
              onChange={(e) => {
                setCurrentSelectedFlight({
                  ...currentSelectedFlight,
                  flightArrivalTime: e.target.value,
                });
              }}
              disabled={!userRoles.includes(DS_F2W_Business_Travel_Team)}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Travel Type</Form.Label>
            <Select
              isDisabled={!userRoles.includes(DS_F2W_Business_Travel_Team)}
              options={mappedTravelTypes}
              isSearchable
              isClearable
              value={mappedTravelTypes.find((x) => x.value === currentSelectedFlight.travelType)}
              onChange={(e) => {
                setValidationFlightFormErrors({});
                if (!e || !e.value) {
                  setCurrentSelectedFlight({
                    ...currentSelectedFlight,
                    travelType: '',
                  });
                  return;
                }
                setCurrentSelectedFlight({
                  ...currentSelectedFlight,
                  travelType: e.value,
                });
              }}
            />
            {validationFlightFormErrors.travelType && (
              <small className="form-text-red">{validationFlightFormErrors.travelType}</small>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Payment Method</Form.Label>
            <Select
              isDisabled={!userRoles.includes(DS_F2W_Business_Travel_Team)}
              options={mappedPaymentMethods}
              isSearchable
              isClearable
              value={mappedPaymentMethods.find((x) => x.value === currentSelectedFlight.paymentMethod)}
              onChange={(e) => {
                setValidationFlightFormErrors({});
                if (!e || !e.value) {
                  setCurrentSelectedFlight({
                    ...currentSelectedFlight,
                    paymentMethod: '',
                  });
                  return;
                }
                setCurrentSelectedFlight({
                  ...currentSelectedFlight,
                  paymentMethod: e.value,
                });
              }}
            />
            {validationFlightFormErrors.paymentMethod && (
              <small className="form-text-red">{validationFlightFormErrors.paymentMethod}</small>
            )}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Luggage</Form.Label>
            {/* <Form.Control
              type="text"
              value={currentSelectedFlight?.luggage || '0'}
              onChange={(e) => {
                setValidationFlightFormErrors({});
                setCurrentSelectedFlight({
                  ...currentSelectedFlight,
                  luggage: e.target.value,
                });
              }}
              disabled={!userRoles.includes(DS_F2W_Business_Travel_Team)}
            /> */}
            <Select
              isDisabled={!userRoles.includes(DS_F2W_Business_Travel_Team)}
              options={validLuggageSelectOptions}
              isSearchable
              isClearable
              value={validLuggageSelectOptions.find((x) => x.value === currentSelectedFlight.luggage)}
              onChange={(e) => {
                setValidationFlightFormErrors({});
                if (!e || !e.value) {
                  setCurrentSelectedFlight({
                    ...currentSelectedFlight,
                    luggage: '',
                  });
                  return;
                }
                setCurrentSelectedFlight({
                  ...currentSelectedFlight,
                  luggage: e.value,
                });
              }}
            />
            {validationFlightFormErrors.luggage && (
              <small className="form-text-red">{validationFlightFormErrors.luggage}</small>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Xbag Cost</Form.Label>
            <Form.Control
              type="number"
              min="0"
              step="0.5"
              value={currentSelectedFlight?.xbagCost}
              onChange={(e) => {
                setValidationFlightFormErrors({});
                setCurrentSelectedFlight({
                  ...currentSelectedFlight,
                  xbagCost: e.target.value,
                });
              }}
              placeholder="0.0"
              disabled={!userRoles.includes(DS_F2W_Business_Travel_Team)}
            />
            {validationFlightFormErrors.xbagCost && (
              <small className="form-text-red">{validationFlightFormErrors.xbagCost}</small>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Flight Cost</Form.Label>
            <Form.Control
              type="number"
              min="0"
              step="0.5"
              value={currentSelectedFlight.flightCost}
              onChange={(e) => {
                setValidationFlightFormErrors({});
                setCurrentSelectedFlight({
                  ...currentSelectedFlight,
                  flightCost: e.target.value,
                });
              }}
              placeholder="0.0"
              disabled={!userRoles.includes(DS_F2W_Business_Travel_Team)}
            />
            {validationFlightFormErrors.flightCost && (
              <small className="form-text-red">{validationFlightFormErrors.flightCost}</small>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Other Transport Cost</Form.Label>
            <Form.Control
              type="number"
              min="0"
              step="0.5"
              value={currentSelectedFlight.otherTransportCost}
              onChange={(e) => {
                setValidationFlightFormErrors({});
                setCurrentSelectedFlight({
                  ...currentSelectedFlight,
                  otherTransportCost: e.target.value,
                });
              }}
              placeholder="0.0"
              disabled={!userRoles.includes(DS_F2W_Business_Travel_Team)}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Hotel Cost</Form.Label>
            <Form.Control
              type="number"
              min="0.0"
              step="0.5"
              value={currentSelectedFlight.hotelCost}
              onChange={(e) => {
                setValidationFlightFormErrors({});
                setCurrentSelectedFlight({
                  ...currentSelectedFlight,
                  hotelCost: e.target.value,
                });
              }}
              placeholder="0.0"
              disabled={!userRoles.includes(DS_F2W_Business_Travel_Team) || !flyToWorkTicketInfo.isHotelNeeded}
            />
            {validationFlightFormErrors.hotelCost && (
              <small className="form-text-red">{validationFlightFormErrors.hotelCost}</small>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Cost Center</Form.Label>
            <CreatableSelect
              isDisabled={!userRoles.includes(DS_F2W_Business_Travel_Team)}
              value={
                costCentreOptions.find((option) => option.value === currentSelectedFlight.costCentre) || {
                  value: currentSelectedFlight.costCentre,
                  label: currentSelectedFlight.costCentre,
                }
              }
              onChange={(e) => {
                setValidationFlightFormErrors({});
                setCurrentSelectedFlight({
                  ...currentSelectedFlight,
                  costCentre: e ? e.value : '',
                });
              }}
              options={costCentreOptions}
              isClearable
            />
            {validationFlightFormErrors.costCentre && (
              <small className="form-text-red">{validationFlightFormErrors.costCentre}</small>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Total Travel Cost</Form.Label>
            <Form.Control type="number" min="0.0" step="0.5" value={totalCost || 0} placeholder="0.0" readOnly />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Currency</Form.Label>
            <Select
              isDisabled={!userRoles.includes(DS_F2W_Business_Travel_Team)}
              options={currencySelectOptions}
              isSearchable
              isClearable
              value={currencySelectOptions.filter((x) => x.value === currentSelectedFlight.currency)}
              onChange={(e) => {
                setValidationFlightFormErrors({});
                if (!e || !e.value) {
                  setCurrentSelectedFlight({
                    ...currentSelectedFlight,
                    currency: '',
                  });
                  return;
                }
                setCurrentSelectedFlight({
                  ...currentSelectedFlight,
                  currency: e.value,
                });
              }}
            />
            {validationFlightFormErrors.currency && (
              <small className="form-text-red">{validationFlightFormErrors.currency}</small>
            )}
          </Form.Group>
        </Form.Row>
        <div hidden={!flyToWorkTicketInfo.isHotelNeeded}>
          <hr></hr>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Hotel Name</Form.Label>
              <Form.Control
                type="text"
                value={currentSelectedFlight.hotelName}
                onChange={(e) => {
                  setValidationFlightFormErrors({});
                  setCurrentSelectedFlight({
                    ...currentSelectedFlight,
                    hotelName: e.target.value,
                  });
                }}
              />
              {validationFlightFormErrors.hotelName && (
                <small className="form-text-red">{validationFlightFormErrors.hotelName}</small>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Check-in date</Form.Label>
              <Datetime
                timeFormat={false}
                dateFormat={DATE_FORMAT_WEB}
                closeOnSelect
                utc={true}
                inputProps={{ placeholder: DATE_FORMAT_WEB }}
                value={DateTimeFormater(currentSelectedFlight.hotelCheckInDate, null, DATE_FORMAT_WEB)}
                onChange={(e) => {
                  setValidationFlightFormErrors({});
                  setCurrentSelectedFlight({
                    ...currentSelectedFlight,
                    hotelCheckInDate: DateTimeFormater(e, null, DATETIME_FORMAT_ISO),
                  });
                }}
                // isValidDate={(current) => {
                //   return current.isAfter(flyToWorkTicketInfo.ticketStartDate);
                // }}
              />
              {validationFlightFormErrors.hotelCheckInDate && (
                <small className="form-text-red">{validationFlightFormErrors.hotelCheckInDate}</small>
              )}
            </Form.Group>
            <Form.Group as={Col} hidden={flyToWorkTicketInfo.status.toLowerCase().trim() !== 'pendingbtt'}>
              <Form.Label>Check-out date</Form.Label>
              <Datetime
                timeFormat={false}
                dateFormat={DATE_FORMAT_WEB}
                closeOnSelect
                utc={true}
                inputProps={{ placeholder: DATE_FORMAT_WEB }}
                value={DateTimeFormater(currentSelectedFlight.hotelCheckOutDate, null, DATE_FORMAT_WEB)}
                onChange={(e) => {
                  setValidationFlightFormErrors({});
                  setCurrentSelectedFlight({
                    ...currentSelectedFlight,
                    hotelCheckOutDate: DateTimeFormater(e, null, DATETIME_FORMAT_ISO),
                  });
                }}
                // isValidDate={(current) => {
                //   return current.isAfter(flyToWorkTicketInfo.hotelCheckInDate);
                // }}
              />
              {validationFlightFormErrors.hotelCheckOutDate && (
                <small className="form-text-red">{validationFlightFormErrors.hotelCheckOutDate}</small>
              )}
            </Form.Group>
          </Form.Row>
        </div>
      </Card.Body>
      <Card.Footer>
        <Row style={{ display: 'flex' }}>
          {userRoles.includes(DS_F2W_Business_Travel_Team) && (
            <Button onClick={() => handleAddFlightToTicket()} variant="warning" style={{ margin: '0 5px' }} size="sm">
              {userRoles.includes(DS_F2W_Business_Travel_Team) ? 'Update flight' : 'Add flight'}
            </Button>
          )}
          {userRoles.includes(DS_F2W_Business_Travel_Team) && !showCloseButtonOnModal && (
            <Button
              onClick={() => handleRemoveFlightFromTicket()}
              variant="danger"
              style={{ margin: '0 5px' }}
              size="sm"
            >
              Remove
            </Button>
          )}
          {userRoles.includes(DS_F2W_Business_Travel_Team) && showCloseButtonOnModal && (
            <Button onClick={() => setShowAddFlightModal(false)} variant="info" style={{ margin: '0 5px' }} size="sm">
              Close
            </Button>
          )}
        </Row>
      </Card.Footer>
    </Card>
  );
};

export default FlightCard;
