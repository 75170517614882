import React, { useEffect } from 'react';
// bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
// react-datetime css
import 'react-datetime/css/react-datetime.css';
// agGrid component with css
import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey('TUI_Nordic__TPP_1Devs6_March_2020__MTU4MzQ1MjgwMDAwMA==76d945771851586658d23f986e58136d');
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-fresh.css';
import { datadogRum } from '@datadog/browser-rum';
import { authContext } from 'Configuration/AdalConfiguration';

import Layout from 'Layout/Layout';
import TppSwitcher from 'Layout/Components/F2wSwitcher';
import { useDispatch } from 'react-redux';
import { getSeaons, getCountries, getDestinations, getJobTitles } from 'Redux/Actions/MasterData/MasterDataAction';
import { getUser } from 'Redux/Actions/User/UserAction';
import { getEnvironmentVariablesHandler } from 'Handlers/EnvHandler';

const APP_CA = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSeaons());
    dispatch(getCountries());
    dispatch(getDestinations());
    dispatch(getJobTitles());

    dispatch(getUser());
  }, []);

  console.log('Environment Variables:', getEnvironmentVariablesHandler());

  datadogRum.init({
    applicationId: 'a6f03881-8aec-4ac4-bbec-80f6c7771b46',
    clientToken: 'pub509050ee472a98df5ff01e6c71a74789',
    site: 'datadoghq.eu',
    service: 'fly2work-web',
    env: getEnvironmentVariablesHandler().environment,
    version: getEnvironmentVariablesHandler().version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });

  var { profile } = authContext.getCachedUser();
  datadogRum.setUser({
    email: profile.upn,
    name: profile.given_name,
  });
  return (
    <Layout>
      <TppSwitcher />
    </Layout>
  );
};

export default APP_CA;
