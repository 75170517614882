import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { runWithAdal } from 'react-adal';

import App from 'App_CA';
import Unauthorized from 'Views/Unauthorized';
import store from 'Configuration/StoreConfiguration';
import { authContext } from 'Configuration/AdalConfiguration';
import { VALID_LOGIN_F2W_ROLES } from 'Constants/F2wRolesConstants';

const renderApp = (Component) => {
  ReactDOM.render(<Provider store={store}>{Component}</Provider>, document.getElementById('root'));
};

runWithAdal(authContext, async () => {
  try {
    // get adal profile user
    var { profile } = authContext.getCachedUser();

    // get roles from adal profile user and validate
    var roles = profile.roles;
    var validRoles = roles.filter((role) => VALID_LOGIN_F2W_ROLES.includes(role));
    if (validRoles.length === 0) {
      throw new Error('401');
    }

    renderApp(<App />);
  } catch (error) {
    renderApp(<Unauthorized errorMessage={error.message} />);
  }
});
