const initialState = {
  apiBaseUrl: 'unknown',
  environment: 'unknown',
  isLoading: false,
};

const EnvironmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TPP_WEB_ENVIRONMENT_SUCCESS':
      return {
        ...state,
        ...action.data.environmentVariables,
      };
    default:
      return state;
  }
};

export default EnvironmentReducer;
