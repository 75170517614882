import React, { useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.css';

import { authContext } from 'Configuration/AdalConfiguration';

const Unauthorized = (props) => {
  const { errorMessage } = props;
  const profile = authContext.getCachedUser().profile;

  const checkErrorMessages = (errorMessage) => {
    switch (errorMessage) {
      case '401':
        return {
          errorMessage: 'Unauthorized, your roles are not correct for this application',
          errorCode: 401,
          errorCurrentValue: profile.roles?.join(', '),
          errorType: 'Roles error',
          errorSolveProblem: 'Please contact your administrator, you don´t have any valid role.',
        };
      default:
        return {
          errorMessage: 'Unknown error, we don´t know what happened.',
          errorCurrentValue: 'Unknown, auth value : ' + JSON.stringify(profile),
          errorCode: 500,
          errorType: 'Unknown',
          errorSolveProblem: 'Please contact your administrator, you don´t have any valid role.',
        };
    }
  };
  const preCheckModel = checkErrorMessages(errorMessage);

  useEffect(() => {
    document.title = 'F2w - Unauthorized';
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Card style={{ margin: '5% 0', padding: '2%' }}>
          <Row style={{ justifyContent: 'center', textAlign: 'center', padding: '5% 0' }}>
            <Col>
              <i className="fa fa-hand-spock-o fa-5x">{preCheckModel.errorCode}</i>
            </Col>
          </Row>
          <Row style={{ justifyContent: 'center', textAlign: 'center' }}>
            <Col>
              <h1>Wait a second {profile.name}! ... something seems strange to us</h1>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col lg={{ span: 6, offset: 4 }}>
              <h3>{preCheckModel.errorType}</h3>
              <br></br>
              <p>
                <strong>Problem :</strong> {preCheckModel.errorMessage}
              </p>
              <p>
                <strong>Current value :</strong> {preCheckModel.errorCurrentValue}
              </p>
              <p>
                <strong>Situation :</strong> {preCheckModel.errorSolveProblem}
              </p>
            </Col>
          </Row>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default Unauthorized;
