import React from 'react';

import F2wSideBar from 'Components/F2wSideBar';

const SideBar = () => {
  const renderCorrectSidebar = () => {
    const pathName = window.location.pathname;
    const regex =
      /^\/(new|confirmed|pendingbtt|pendinght)\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
    let result = null;

    if (!regex.test(pathName)) {
      return <F2wSideBar />;
    }

    return result;
  };

  if (renderCorrectSidebar() == null) return <React.Fragment></React.Fragment>;
  return <div className="sidebar">{renderCorrectSidebar()}</div>;
};

export default SideBar;
