import React, { useEffect } from 'react';

import StatusPendingDesAgGridTable from 'Views/PendingDes/AgGridTable/StatusPendingDesAgGridTable';

const StatusPendingDes = () => {
  useEffect(() => {
    document.title = 'F2W - PendingDes';
  }, []);
  return (
    <React.Fragment>
      <h1>Pending DES</h1>
      <StatusPendingDesAgGridTable />
    </React.Fragment>
  );
};

export default StatusPendingDes;
