import React from 'react';

import StatusStaffTabsRow from 'Components/StatusStaffTabsRow';
import RequestInformationCard from 'Views/TicketProfile/Components/RequestInformationCard';
import TicketIdentifiersCard from 'Views/TicketProfile/Components/TicketIdentifiersCard';

const FlyToWorkTicketInformationTab = (props) => {
  const { flyToWorkTicketInfo } = props;
  return (
    <React.Fragment>
      <StatusStaffTabsRow flyToWorkTicketInfo={flyToWorkTicketInfo} />
      <hr style={{ marginTop: 0 }} />
      <TicketIdentifiersCard flyToWorkTicketInfo={flyToWorkTicketInfo} />
      <RequestInformationCard flyToWorkTicketInfo={flyToWorkTicketInfo} />
    </React.Fragment>
  );
};

export default FlyToWorkTicketInformationTab;
