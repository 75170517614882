import React, { useEffect } from 'react';
import NewAgGridTable from './AgGridTable/NewAgGridTable';
import { Col, Row, Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import NewFlightTicketModal from 'Views/New/Components/NewFlightTicketModal';
import RestClient from 'Services/RestClientService';
import { setLoader } from 'Redux/Actions/Loading/LoadingAction';
import { toastr } from 'react-redux-toastr';
import { DS_F2W_Edit } from 'Constants/F2wRolesConstants';

const StatusNew = () => {
  const dispatch = useDispatch();
  const initialModel = {
    firstName: '',
    lastName: '',
    lastName2: '',

    dateOfBirth: '',
    phone: '',

    gender: '',
    staffSourceMarket: '',
    staffSourceMarketBudget: '',
    season: '',

    country: '',
    destination: '',
    jobTitle: '',

    preferredFlightDate: null,
    typeOfFlight: '',

    status: 'PendingBtt',
    isBookReturnFlight: false,

    brfReturnFlightDate: null,
    brfDepartureAirport: '',
    brfArrivalAirport: '',

    isHotelNeeded: false,
    departureAirports: null,
    arrivalAirports: null,

    isRailAndFly: false,
    homeTrainStationName: '',

    noFlightNeeded: false,
  };
  const [showAddCommentModal, setShowAddCommentModal] = React.useState(false);
  const [newManualFlightTicket, setNewManualFlightTicket] = React.useState(initialModel);
  const [newManualFlightTicketErrors, setNewManualFlightTicketErrors] = React.useState({});

  const user = useSelector((state) => state.currentUser);
  const userRoles = user?.roles;

  const handleAddManualFlightTicket = (payload) => {
    //console.log('handleAddManualFlightTicket', payload);

    const errors = {};
    const flightFields = Object.keys(payload);

    for (let i = 0; i < flightFields.length; i++) {
      const field = flightFields[i];

      if (
        field === 'lastName2' ||
        field === 'isBookReturnFlight' ||
        field === 'isHotelNeeded' ||
        field === 'isRailAndFly' ||
        field === 'noFlightNeeded' ||
        field === 'flightArrivalTime'
      ) {
        continue;
      }

      if (payload[field] === null || payload[field] === '' || !payload[field]) {
        errors[field] = `Please fill in the ${field}`;
      }
    }

    // no comporbar errores si isBookReturnFlight es falso
    if (!payload.isBookReturnFlight) {
      delete errors.brfReturnFlightDate;
      delete errors.brfDepartureAirport;
      delete errors.brfArrivalAirport;
    }

    // no comporbar errores si isRailAndFly es falso
    if (!payload.isRailAndFly) {
      delete errors.homeTrainStationName;
    }

    // no comporbar errores si isRailAndFly es falso
    if (payload.noFlightNeeded) {
      delete errors.departureAirports;
      delete errors.arrivalAirports;
    }

    if (Object.keys(errors).length > 0) {
      setNewManualFlightTicketErrors(errors);
      return;
    }

    setShowAddCommentModal(false);
    dispatch(setLoader(true));
    RestClient.Post('flight-ticket/insert-manual', payload)
      .then((response) => {
        // console.log('response', response);
        if (response && response?.ok) {
          // var url = location.protocol + '//' + location.host + '/new/' + response.id;
          // var messgeHtml = `Flight ticket added successfully. <a href="${url}" target="_blank">Click here</a> to view the ticket.`;

          toastr.success('Success', response.message);
          return;
        }

        toastr.error('Error', response.message);
      })
      .finally(() => {
        dispatch(setLoader(false));
        setNewManualFlightTicket(initialModel);
      });
  };

  useEffect(() => {
    document.title = 'F2W - New';
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <h1>New</h1>
          <Button
            variant="primary"
            size="sm"
            style={{ margin: '0 2%' }}
            onClick={() => {
              setShowAddCommentModal(true);
            }}
            hidden={!userRoles.includes(DS_F2W_Edit)}
          >
            Add Manual Request
          </Button>
        </Col>
      </Row>

      <NewAgGridTable />

      <Modal
        size="lg"
        show={showAddCommentModal}
        onHide={() => {
          setShowAddCommentModal(!showAddCommentModal);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Submit ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewFlightTicketModal
            newManualFlightTicket={newManualFlightTicket}
            setNewManualFlightTicket={setNewManualFlightTicket}
            newManualFlightTicketErrors={newManualFlightTicketErrors}
            setNewManualFlightTicketErrors={setNewManualFlightTicketErrors}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" size="sm" onClick={() => handleAddManualFlightTicket(newManualFlightTicket)}>
            Add ticket
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default StatusNew;
