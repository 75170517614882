import React, { useEffect, useState } from 'react';
import { Card, Table, Col, Row } from 'react-bootstrap';

import StatusStaffTabsRow from 'Components/StatusStaffTabsRow';
import { DATETIME_FORMAT_WEB } from 'Constants/DateFormatConstants';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';

const HistoryTab = (props) => {
  const { flyToWorkTicketInfo, refreshComponent } = props;
  const [flightTicketAudit, setFlightTicketAudit] = useState([]);

  useEffect(() => {
    if (!flyToWorkTicketInfo.audit || flyToWorkTicketInfo.audit.length === 0) return;

    if (!Array.isArray(flyToWorkTicketInfo.audit)) flyToWorkTicketInfo.audit = [flyToWorkTicketInfo.audit];

    setFlightTicketAudit(flyToWorkTicketInfo.audit);
  }, [flyToWorkTicketInfo, refreshComponent]);

  return (
    <React.Fragment>
      <StatusStaffTabsRow flyToWorkTicketInfo={flyToWorkTicketInfo} />
      <hr style={{ marginTop: 0 }}></hr>
      <Row>
        <Col lg={12}>
          <Card className="staff-profile-card">
            <Card.Header>
              <i className="fa fa-book mr-2"></i>History audit
            </Card.Header>
            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>History date</th>
                    <th>Who´s doing the change</th>
                    <th>Group</th>
                    <th>Status from</th>
                    <th>Status to</th>
                    <th>Greenlight from</th>
                    <th>Greenlight to</th>
                  </tr>
                </thead>
                <tbody>
                  {!flightTicketAudit || flightTicketAudit.length == 0 ? (
                    <tr>
                      <td colSpan="7" align="center">
                        No results found
                      </td>
                    </tr>
                  ) : (
                    flightTicketAudit?.map((audit, index) => {
                      // console.log('audit', audit);
                      return (
                        <tr key={index}>
                          <td>{DateTimeFormater(audit.date, null, DATETIME_FORMAT_WEB)}</td>
                          <td>{audit.updatedBy}</td>
                          <td>{audit.group}</td>
                          <td>{audit.statusFrom}</td>
                          <td>{audit.statusTo}</td>
                          <td>{audit.greenLightFrom}</td>
                          <td>{audit.greenLightTo}</td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default HistoryTab;
